import { Payload } from "@local/payload-client/src/types";
import Script from "next/script";
interface Props {
  item: Payload.Anchor | Payload.Mini;
  brand: string;
  slug?: string;
}
export const OrganizationSchemaJsonLd = ({
  item,
  brand,
  slug
}: Props) => {
  return <Script type="application/ld+json" id={`json-ld-organization-${brand}`} data-sentry-element="Script" data-sentry-component="OrganizationSchemaJsonLd" data-sentry-source-file="Organization.tsx">
      {JSON.stringify({
      "image": (item.meta?.image as Payload.Media)?.url,
      "url": (process.env.NEXT_PUBLIC_APP_URL as string) + (slug ? `/lp/${slug}` : ""),
      "description": item.meta?.description,
      "name": brand,
      "@context": "https://schema.org",
      "@type": "Organization",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": " 16192 Coastal Highway 19958 Lewes - Delaware",
        "addressLocality": "Lewes",
        "addressRegion": "Delaware",
        "postalCode": "19958",
        "addressCountry": "US"
      }
    })}
    </Script>;
};