"use client";

import { classNames } from "@local/utils/src/classNames";
import { useCartLayout } from "./useCartLayout";
import { CloseIcon } from "../ui/icons";
import { useEffect } from "react";
import { isBrowser } from "@local/utils";
import { Cart } from ".";
import { type Payload } from "@local/payload-client/src/types";
interface Props {
  promo?: string[];
  freeGifts?: Payload.FreeGifts;
  giftWrap?: Payload.Product | null;
}
export const CartSlideOverClient = ({
  promo,
  freeGifts,
  giftWrap
}: Props) => {
  const {
    toggleOpen: toggleCart,
    isOpen: open
  } = useCartLayout();

  // layout and page files can't be client components, so we do this
  useEffect(() => {
    if (isBrowser()) {
      if (open) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }
  }, [open]);
  return <div className={classNames("relative z-[240]", open ? "opacity-100" : "opacity-0")} style={{
    transition: "opacity linear 300ms"
  }} role="form" data-sentry-component="CartSlideOverClient" data-sentry-source-file="CartSlideOver.tsx">
      <div className={classNames("fixed inset-0 z-50 flex", open ? "visible block" : "invisible hidden", "bg-gray-600 bg-opacity-50 w-full backdrop-blur-sm")} onClick={e => e.preventDefault()} />
      <div className={classNames("fixed inset-0 z-50 flex right-0", open ? "translate-x-0" : "translate-x-full")} style={{
      transition: "transform ease-in-out 300ms"
    }}>
        <div className="w-full h-full" onClick={() => toggleCart(false)} />
        <div className="absolute right-0 flex flex-col w-full h-full p-4 pb-12 overflow-y-auto text-black bg-white shadow-xl sm:p-8 sm:pb-12 md:max-w-2xl">
          <div className="flex flex-row items-center justify-between mb-4 align-middle">
            <h2 className="text-xl font-semibold">Your Cart</h2>
            <div>
              <button onClick={() => toggleCart(false)} className="hover:opacity-75">
                <span className="sr-only">Close Cart</span>
                <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="CartSlideOver.tsx" />
              </button>
            </div>
          </div>
          <Cart promo={promo} freeGifts={freeGifts} giftWrap={giftWrap} data-sentry-element="Cart" data-sentry-source-file="CartSlideOver.tsx" />
        </div>
      </div>
    </div>;
};
export default CartSlideOverClient;