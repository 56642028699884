"use client";

import { STORE_EMAIL } from "@/lib/const";
import { isBrowser } from "@local/utils";
import Link from "next/link";
import { useEffect, useState } from "react";
export const GorgiasLink = ({
  string
}: {
  string: string;
}) => {
  const [hasGorgias, setHasGorgias] = useState(false);
  useEffect(() => {
    setHasGorgias(isBrowser() && window?.GorgiasChat ? true : false);
  }, []);
  return hasGorgias ? <button onClick={() => window.GorgiasChat.open()} data-sentry-component="GorgiasLink" data-sentry-source-file="GorgiasLink.tsx">
      {string ?? "Chat with us"}
    </button> : <Link href={`mailto:${STORE_EMAIL}`} target="_blank" rel="noreferrer" aria-label="Redirect to send email in a new tab" data-sentry-element="Link" data-sentry-component="GorgiasLink" data-sentry-source-file="GorgiasLink.tsx">
      {string ?? "Chat with us"}
    </Link>;
};